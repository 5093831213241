import React from 'react'
import { Link } from "@inertiajs/react";

const Breadcrumb = ({ page }) => {

    const styles = {
        color: "#024f6e",
        fontWeight: "bold",
    }

  return (
    // style = ,
    <nav aria-label="breadcrumb">

        <ol className="breadcrumb">
            <li className="breadcrumb-item">
                <Link href={route("dashboard")} style={styles}>Dashboard</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
               {page}
            </li>
        </ol>
    </nav>
  )
}

export default Breadcrumb